@media (min-width: 1400px) {
  .row-cols-xxl-7 > * {
    flex: 0 0 auto;
    width: 14.2857142857%
  }

  .row-cols-xxl-8 > * {
    flex: 0 0 auto;
    width: 12.5%
  }
}
