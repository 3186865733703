@media (min-width: 3000px) {
  .col-xxxxxl-24 {
    flex: 0 0 auto;
    width: 100% !important;
  }

  .col-xxxxxl-23 {
    flex: 0 0 auto;
    width: 95.83333333% !important;
  }

  .col-xxxxxl-22 {
    flex: 0 0 auto;
    width: 91.66666667% !important;
  }

  .col-xxxxxl-21 {
    flex: 0 0 auto;
    width: 87.5% !important;
  }

  .col-xxxxxl-20 {
    flex: 0 0 auto;
    width: 83.33333333% !important;
  }

  .col-xxxxxl-19 {
    flex: 0 0 auto;
    width: 79.16666666% !important;
  }

  .col-xxxxxl-18 {
    flex: 0 0 auto;
    width: 75% !important;
  }

  .col-xxxxxl-17 {
    flex: 0 0 auto;
    width: 70.83333333% !important;
  }

  .col-xxxxxl-16 {
    flex: 0 0 auto;
    width: 66.66666667% !important;
  }

  .col-xxxxxl-15 {
    flex: 0 0 auto;
    width: 62.5% !important;
  }

  .col-xxxxxl-14 {
    flex: 0 0 auto;
    width: 58.33333333% !important;
  }

  .col-xxxxxl-13 {
    flex: 0 0 auto;
    width: 54.16666666% !important;
  }

  .col-xxxxxl-12 {
    flex: 0 0 auto;
    width: 50% !important;
  }

  .col-xxxxxl-11 {
    flex: 0 0 auto;
    width: 45.83333333% !important;
  }

  .col-xxxxxl-10 {
    flex: 0 0 auto;
    width: 41.66666667% !important;
  }

  .col-xxxxxl-9 {
    flex: 0 0 auto;
    width: 37.5% !important;
  }

  .col-xxxxxl-8 {
    flex: 0 0 auto;
    width: 33.33333333% !important;
  }

  .col-xxxxxl-7 {
    flex: 0 0 auto;
    width: 29.16666666% !important;
  }

  .col-xxxxxl-6 {
    flex: 0 0 auto;
    width: 25% !important;
  }

  .col-xxxxxl-5 {
    flex: 0 0 auto;
    width: 20.83333333% !important;
  }

  .col-xxxxxl-4 {
    flex: 0 0 auto;
    width: 16.66666667% !important;
  }

  .col-xxxxxl-3 {
    flex: 0 0 auto;
    width: 12.5% !important;
  }

  .col-xxxxxl-2 {
    flex: 0 0 auto;
    width: 8.33333333% !important;
  }

  .col-xxxxxl-1 {
    flex: 0 0 auto;
    width: 4.16666666% !important;
  }

  .col-xxxxxl{
    flex:1 0 0;
  }

  .order-xxxxxl-first {
    order: -1
  }

  .order-xxxxxl-last {
    order: 13
  }

  .order-xxxxxl-0 {
    order: 0
  }

  .order-xxxxxl-1 {
    order: 1
  }

  .order-xxxxxl-2 {
    order: 2
  }

  .order-xxxxxl-3 {
    order: 3
  }

  .order-xxxxxl-4 {
    order: 4
  }

  .order-xxxxxl-5 {
    order: 5
  }

  .order-xxxxxl-6 {
    order: 6
  }

  .order-xxxxxl-7 {
    order: 7
  }

  .order-xxxxxl-8 {
    order: 8
  }

  .order-xxxxxl-9 {
    order: 9
  }

  .order-xxxxxl-10 {
    order: 10
  }

  .order-xxxxxl-11 {
    order: 11
  }

  .order-xxxxxl-12 {
    order: 12
  }

  .row-cols-xxxxxl-auto > * {
    flex: 0 0 auto;
    width: auto
  }

  .row-cols-xxxxxl-1 > * {
    flex: 0 0 auto;
    width: 100%
  }

  .row-cols-xxxxxl-2 > * {
    flex: 0 0 auto;
    width: 50%
  }

  .row-cols-xxxxxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%
  }

  .row-cols-xxxxxl-4 > * {
    flex: 0 0 auto;
    width: 25%
  }

  .row-cols-xxxxxl-5 > * {
    flex: 0 0 auto;
    width: 20%
  }

  .row-cols-xxxxxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%
  }

  .row-cols-xxxxxl-7 > * {
    flex: 0 0 auto;
    width: 14.2857142857%
  }

  .row-cols-xxxxxl-8 > * {
    flex: 0 0 auto;
    width: 12.5%
  }
}
